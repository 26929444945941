import moment from "moment";
import { APPROVE } from "src/common/constants";
export const APPROVE_FETCH = `${APPROVE}_GET`;
export const APPROVE_FETCH_INVOKED = `${APPROVE}_GET_INVOKED`;
export const APPROVE_FETCH_SUCCESS = `${APPROVE}_GET_SUCCESS`;
export const APPROVE_FETCH_FAILED = `${APPROVE}_GET_FAILED`;
export const APPROVE_FETCH_ONE = `${APPROVE}_GET_ONE`;
export const APPROVE_FETCH_ONES = `${APPROVE}_GET_ONES`;
export const APPROVE_FETCH_ONE_SUCCESS = `${APPROVE}_GET_ONE_SUCCESS`;
export const APPROVE_FETCH_ONE_FAILED = `${APPROVE}_GET_ONE_FAILED`;
export const APPROVE_DATA_SORT_SAGA = `${APPROVE}_DATA_SORT_SAGA`;
export const APPROVE_DATA_SORT = `${APPROVE}_DATA_SORT`;
export const APPROVE_SET_QUERY_SAGA = `${APPROVE}_SET_QUERY_SAGA`;
export const APPROVE_SET_QUERY = `${APPROVE}_SET_QUERY`;
export const APPROVE_SET_DATES_SAGA = `${APPROVE}_SET_DATES_SAGA`;
export const APPROVE_SET_DATES = `${APPROVE}_SET_DATES`;
export const APPROVE_SET_PAGE_SAGA = `${APPROVE}_SET_PAGE_SAGA`;
export const APPROVE_SET_PAGE = `${APPROVE}_SET_PAGE`;
export const APPROVE_SET_TAB_SAGA = `${APPROVE}_SET_TAB_SAGA`;
export const APPROVE_SET_TAB = `${APPROVE}_SET_TAB`;
export const APPROVE_SET_APP_STATUSES = `${APPROVE}_SET_APP_STATUSES`;
export const APPROVE_SET_APP_STATUSES_SAGA = `${APPROVE}_SET_APP_STATUSES_SAGA`;
export const APPROVE_SET_BOOKING_STATUSES = `${APPROVE}_SET_BOOKING_STATUSES`;
export const APPROVE_SET_BOOKING_STATUSES_SAGA = `${APPROVE}_SET_BOOKING_STATUSES_SAGA`;

export interface SetApproveQueryI {
  query: string;
  id: string;
}

export const setApproveQuery = ({ query, id }: SetApproveQueryI) => {
  return {
    type: APPROVE_SET_QUERY,
    query,
    id,
  };
};

export interface SetApproveDatesI {
  startDate: moment.Moment;
  endDate: moment.Moment;
  id: string;
}

export const setApproveDates = ({
  startDate,
  endDate,
  id,
}: SetApproveDatesI) => {
  return {
    type: APPROVE_SET_DATES,
    startDate,
    endDate,
    id,
  };
};

export interface SetApprovePageI {
  page: number;
  id: string;
}

export const setApprovePage = ({ page, id }: SetApprovePageI) => {
  return {
    type: APPROVE_SET_PAGE,
    page,
    id,
  };
};

export interface SetApproveTabI {
  selectedTab: string;
  id: string;
}

export const setApproveTab = ({ selectedTab, id }: SetApproveTabI) => {
  return {
    type: APPROVE_SET_TAB,
    selectedTab,
    id,
  };
};

export interface SetApprovalStatusesI {
  approvalStatuses: number[];
  id: string;
}

export const setApprovalStatuses = ({
  approvalStatuses,
  id,
}: SetApprovalStatusesI) => {
  return {
    type: APPROVE_SET_APP_STATUSES,
    approvalStatuses,
    id,
  };
};

export interface SetBookingStatusesI {
  bookingStatuses: number[];
  id: string;
}

export const setBookingStatuses = ({
  bookingStatuses,
  id,
}: SetBookingStatusesI) => {
  return {
    type: APPROVE_SET_BOOKING_STATUSES,
    bookingStatuses,
    id,
  };
};

export interface SortApproveDataI {
  sortColumn: string;
  sortDirection: "ascending" | "descending";
}

export const sortApproveData = (data: SortApproveDataI, id: string) => {
  return {
    type: APPROVE_DATA_SORT,
    id,
    ...data,
  };
};
