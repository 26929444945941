import moment from "moment";
import {
  APPROVE_DATA_SORT,
  APPROVE_SET_QUERY,
  APPROVE_SET_DATES,
  APPROVE_SET_PAGE,
  APPROVE_SET_TAB,
  APPROVE_SET_APP_STATUSES,
  APPROVE_SET_BOOKING_STATUSES,
} from "src/redux/actions/approve";
import {
  historyApprovalStatuses,
  historyBookingStatuses,
} from "src/services/approve";

export interface InitialApprovalState {
  endDate?: moment.Moment;
  page?: number;
  totalCount?: number;
  query?: string;
  selectedTab?: string;
  sortDirection?: string;
  sortColumn?: string;
  startDate?: moment.Moment;
  transferOptions?: null;
  approvalStatuses?: number[];
  bookingStatuses?: number[];
}

export const initialApprovalState: InitialApprovalState = {
  endDate: null,
  page: 1,
  totalCount: 0,
  query: "",
  selectedTab: "Queue",
  sortDirection: "descending",
  sortColumn: "travelDateFormatted",
  startDate: null,
  transferOptions: null,
  approvalStatuses: historyApprovalStatuses,
  bookingStatuses: historyBookingStatuses,
};

export interface ApprovalStateI {
  ids: {
    [key: string]: InitialApprovalState;
  };
}

const itinState: ApprovalStateI = { ids: {} };

const approve = (state = itinState, action): ApprovalStateI => {
  const result = { ...state };
  const featureSet = result.ids[action.id] || { ...initialApprovalState };
  switch (action.type) {
    case APPROVE_DATA_SORT:
      result.ids[action.id] = {
        ...featureSet,
        sortColumn: action.sortColumn,
        sortDirection: action.sortDirection,
      };
      return result;
    case APPROVE_SET_QUERY:
      result.ids[action.id] = {
        ...featureSet,
        page: 1,
        query: action.query,
      };
      return result;
    case APPROVE_SET_TAB:
      result.ids[action.id] = {
        ...featureSet,
        page: 1,
        selectedTab: action.selectedTab,
      };

      return result;

    case APPROVE_SET_DATES:
      {
        const { startDate, endDate } = action;

        result.ids[action.id] = {
          ...featureSet,
          page: 1,
          startDate,
          endDate,
        };
      }
      return result;

    case APPROVE_SET_PAGE:
      result.ids[action.id] = {
        ...featureSet,
        page: action.page,
      };
      return result;

    case APPROVE_SET_APP_STATUSES:
      result.ids[action.id] = {
        ...featureSet,
        approvalStatuses: action.approvalStatuses,
        page: 1,
      };
      return result;

    case APPROVE_SET_BOOKING_STATUSES:
      result.ids[action.id] = {
        ...featureSet,
        bookingStatuses: action.bookingStatuses,
        page: 1,
      };
      return result;

    default:
      return state;
  }
};

export default approve;
